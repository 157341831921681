<template>
  <div class="uk-grid-medium" uk-grid>

    <div class="uk-width-expand@m">

      <div class="section-small mb-2">
        <div class="section-header pb-0 mt-0 mb-3">
          <div class="section-header-left">
            <h4 class="uk-text-truncate"><i class="uil-plus-square "></i> {{ this.$t('general.quick_menu') }}
            </h4>
          </div>
          <div class="section-header-right">
            <a class="button transparent uk-visible@m" href="#customize-dashboard" uk-toggle><i
                class="uil-layer-group"></i>
              {{ this.$t('general.customize') }}
            </a>
          </div>
        </div>
        <div v-if="active_widgets.data"
          class="uk-grid-small uk-child-width-1-3 uk-child-width-1-4@s uk-text-center mb-4" uk-grid>
          <div v-for="(element, index) in active_widgets.data.active">
            <div class="course-card uk-card-hover foradmin height-100">
              <a href="#" @click.prevent="$router.push({ name: element.route })">
                <span v-if="element.badge" style="font-size: .75rem;
                                            font-weight: 600;
                                            background: #ff5630;
                                            border-radius: 10px;
                                            padding: 5px 7px;
                                            color: white;
                                            position: absolute;
                                            right: .3rem;
                                            top: .3rem;">{{ element.badge }}</span>
                <div class="course-card-body">
                  <i :class="element.icon"></i>
                  <h4>{{ $t(element.key_name) }}</h4>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>

      <hr>

      <div class="section-small">
        <div class="section-header pb-0 mt-0 mb-3">
          <div class="section-header-left">
            <h4 class="uk-text-truncate"><i class="uil-presentation-check"></i> {{ this.$t('general.recently_added') }}
            </h4>
          </div>
          <div class="section-header-right">
            <router-link :to="{ name: 'company.contents.all' }" class="button transparent uk-visible@m">
              <i class=" uil-list-ui-alt"></i>
              <span class="sideNavMenu_item_title">{{ this.$t('home.see_all') }}</span>
            </router-link>
          </div>
        </div>
        <div class="uk-child-width-1-3@m uk-child-width-1-3@s course-card-grid uk-grid-match" uk-grid>
          <div v-for="(item, index) in contentItems" :key="index">
            <div class="course-path-card mb-2">
              <img :src="item.cover_photo_link" alt="" />
              <div class="course-path-card-contents">
                <div class="course-card-info d-flex justify-content-between">
                  <div>
                    <span class="catagroy">{{ item.title }}</span>
                  </div>
                </div>

                <h3> {{ item.name }}</h3>
                <div class="text-center">
                  <router-link :to="{
                    name: 'company.contents.edit',
                    params: { id: item.id },
                  }" class="btn btn-success btn-sm" href="#" type="button">
                    <i class="uil-cog"></i> {{ $t('faq_categories.edit') }}
                  </router-link>
                  <router-link :to="{
                    name: 'company.assignments.add',
                    query: { data_id: item.id, data_title: item.title, data_name: item.name },
                  }" class="btn btn-info btn-sm" href="#" type="button">
                    <i class="uil-location-arrow"></i> {{ $t('menu.company_dashboard.create_assignment') }}
                  </router-link>
                </div>

              </div>
              <div class="course-path-card-footer">
                <div>
                  <h5 uk-tooltip="title: Oluşturma Tarihi; pos: bottom-center">
                    <i class="uil-calendar-alt mr-1"></i>
                    {{ dateFormat(item.created_at) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <hr>
      <div class="section-small">
        <div class="section-header pb-0 mt-0 mb-3">
          <div class="section-header-left">
            <h4 class="uk-text-truncate"><i class="uil-presentation-check"></i>
              {{ this.$t('general.purchased_trainings') }}

            </h4>
          </div>
          <div class="section-header-right">
            <router-link :to="{ name: 'company.education.catalog.purchased' }" class="button transparent uk-visible@m">
              <i class="uil-list-ui-alt"></i>
              <span class="sideNavMenu_item_title">
                {{ this.$t('home.see_all') }}</span>
            </router-link>
          </div>
        </div>
        <div class="uk-child-width-1-3@m uk-child-width-1-3@s course-card-grid uk-grid-match" uk-grid>
          <div v-for="(item, index) in trainingItems.data" :key="index">
            <div class="course-path-card mb-2">
              <img :src="item.training?.cover_image_link" alt="" />
              <div class="course-path-card-contents">
                <div class="course-card-info d-flex justify-content-between">
                  <div>
                    <span class="catagroy">Zorunlu Eğitim</span>
                  </div>
                </div>
                <h3> {{ item.name }}</h3>
                <div class="text-center">
                  <a class="btn btn-secondary btn-sm mb-2" type="button" @click="handleOnClickCard(item.id)">
                    <i class="uil-play-circle"></i> {{ $t('certification.preview') }}
                  </a>
                  <button v-if="(item.number_of_users - item.assigned_users.length) != 0" class="btn btn-info btn-sm"
                    type="button" @click="handleOnClickAssignmentCard(item.id)">
                    <i class="uil-location-arrow"></i>{{ $t('menu.company_dashboard.create_assignment') }}
                  </button>
                  <button v-else class="btn btn-info btn-sm disabled" type="button">
                    <i class="uil-location-arrow"></i> {{ $t('menu.company_dashboard.create_assignment') }}
                  </button>
                </div>
              </div>
              <div class="course-path-card-footer">
                <div>
                  <h5 uk-tooltip="title: Satın Alma Tarihi; pos: bottom-center">
                    <i class="uil-calendar-alt mr-1"></i>
                    {{ dateFormat(item.created_at) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Popular Instructers -->
      <!-- <hr>
      <div class="section-small">

        <div class="uk-position-relative uk-visible-toggle " tabindex="-1" uk-slider="finite: true">

          <div class="grid-slider-header">
            <h4><i class="uil-briefcase-alt"></i> Yöneticiler/Kurumlar </h4>

            <div class="grid-slider-header-link">
              <div class="section-header-right mr-2">
                <a href="egitimlerim.html" class="button transparent uk-visible@m"><i
                    class=" uil-list-ui-alt"></i>
                  Tümünü Gör
                </a>
              </div>
              <a href="#" class="slide-nav-prev" uk-slider-item="previous"></a>
              <a href="#" class="slide-nav-next" uk-slider-item="next"></a>

            </div>
          </div>

          <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s pb-3 uk-grid">
            <li>
              <div class="card">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <img alt="Image placeholder"
                         src="@/assets/images/avatars/avatar-1.jpg"
                         class="avatar  rounded-circle avatar-lg">
                    <span class="avatar-child avatar-badge bg-success"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0"> Gülseren Mutlu </h5>
                  <a href="#" class="d-block text-sm text-muted mb-3">@gulseren.mutlu</a>

                  <div class="d-flex justify-content-between px-4">
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Profilini İncele">
                      <i class="uil-user"></i> </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Mesaj Gönder">
                      <i class="uil-envelope"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit-alt"></i> </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="card">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <img alt="Image placeholder"
                         src="@/assets/images/avatars/avatar-5.jpg"
                         class="avatar  rounded-circle avatar-lg">
                    <span class="avatar-child avatar-badge bg-success"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0"> Karer Eğitim Danışmanlık </h5>
                  <a href="#" class="d-block text-sm text-muted mb-3">@karer</a>

                  <div class="d-flex justify-content-between px-4">
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Profilini İncele">
                      <i class="uil-user"></i> </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Mesaj Gönder">
                      <i class="uil-envelope"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit-alt"></i> </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="card">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <img alt="Image placeholder"
                         src="@/assets/images/avatars/avatar-2.jpg"
                         class="avatar  rounded-circle avatar-lg">
                    <span class="avatar-child avatar-badge bg-success"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0"> Halil Yiğitoğlu </h5>
                  <a href="#" class="d-block text-sm text-muted mb-3">@halil.yigitoglu</a>

                  <div class="d-flex justify-content-between px-4">
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Profilini İncele">
                      <i class="uil-user"></i> </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Mesaj Gönder">
                      <i class="uil-envelope"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit-alt"></i> </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="card">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <img alt="Image placeholder"
                         src="@/assets/images/avatars/avatar-2.jpg"
                         class="avatar  rounded-circle avatar-lg">
                    <span class="avatar-child avatar-badge bg-success"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0"> Halil Yiğitoğlu </h5>
                  <a href="#" class="d-block text-sm text-muted mb-3">@halil.yigitoglu</a>

                  <div class="d-flex justify-content-between px-4">
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Profilini İncele">
                      <i class="uil-user"></i> </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Mesaj Gönder">
                      <i class="uil-envelope"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit-alt"></i> </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="card">
                <div class="card-body text-center">
                  <div class="avatar-parent-child">
                    <img alt="Image placeholder"
                         src="@/assets/images/avatars/avatar-2.jpg"
                         class="avatar  rounded-circle avatar-lg">
                    <span class="avatar-child avatar-badge bg-success"></span>
                  </div>
                  <h5 class="h6 mt-4 mb-0"> Halil Yiğitoğlu </h5>
                  <a href="#" class="d-block text-sm text-muted mb-3">@halil.yigitoglu</a>

                  <div class="d-flex justify-content-between px-4">
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Profilini İncele">
                      <i class="uil-user"></i> </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       uk-tooltip="Mesaj Gönder">
                      <i class="uil-envelope"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-hover btn-circle"
                       :uk-tooltip="$t('faq_categories.edit')">
                      <i class="uil-edit-alt"></i> </a>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div> -->
    </div>

    <div class="uk-width-1-3@m">

      <div class="bg-white uk-card-default k-height-1-1 width-100" data-simplebar uk-sticky="offset:70; bottom:true">
        <div class="p-3 row align-items-center">

          <div class="col-auto">
            <p class="mb-0">{{ this.$t('notice.view') }}</p>

          </div>
          <div class="col">

            <select class="selectpicker">
              <option selected value=""> Satışlar</option>
              <option value="1"> Kullanıcı Pefrormansları</option>
            </select>

          </div>
        </div>

        <hr class="m-0">

        <div class="p-3">
          <h4><i class="uil-chart-line"></i> {{ $t('general.weekly_sales_graffic') }}</h4>
          <h2>
            <!-- <span style="font-size: .875rem;display: block;color: #999;">21-26 Aralık
                2020</span>
14.050 TL -->
          </h2>
          <!-- <canvas id="cjs-linechart"></canvas>
          <a class="btn btn-outline-secondary d-block btn-sm mt-2" href=""><i
              class="uil-list-ui-alt"></i> Daha fazla</a> -->

        </div>

        <hr class="m-0">

        <div v-if="superAdmin()" class="p-3">
          <h5> Bu Hafta Çok Satanlar</h5>

          <div v-for="(weeklyItem, index) in bestSellerWeekly" class="uk-grid-small uk-flex-middle" uk-grid>
            <div class="uk-width-auto"><img :src="weeklyItem.training?.cover_image_link" alt=""
                class="rounded border border-200" width="60"></div>
            <div class="uk-width-expand">
              <p class="uk-text-truncate mb-0"> {{ weeklyItem.name }}</p>
            </div>
            <div class="uk-width-auto"> <span class="badge badge-soft-success mt-n1">
                {{ weeklyItem.net_amount }} TL</span>
            </div>
          </div>
          <router-link :to="{ name: 'company.requests.purchase' }">
            <p class="uk-heading-line uk-text-center mt-2 uk-text-small"><span><i class=" uil-list-ui-alt"></i> Daha
                fazla
              </span>
            </p>
          </router-link>

          <h5 class="mt-4"> En Çok Satanlar</h5>

          <div v-for="(bestSellerItem, index) in bestSeller" :key="index" class="uk-grid-small uk-flex-middle" uk-grid>
            <div class="uk-width-auto"><img :src="bestSellerItem.training?.cover_image_link" alt=""
                class="rounded border border-200" width="60"></div>
            <div class="uk-width-expand">
              <p class="uk-text-truncate mb-0"> {{ bestSellerItem.name }}</p>
            </div>
            <div class="uk-width-auto"> <span class="badge badge-soft-success mt-n1">
                {{ bestSellerItem.net_amount }} TL</span>
            </div>
          </div>

          <router-link :to="{ name: 'company.requests.purchase' }">
            <p class="uk-heading-line uk-text-center mt-2 uk-text-small"><span><i class=" uil-list-ui-alt"></i> Daha
                fazla
              </span>
            </p>
          </router-link>


        </div>

      </div>

    </div>

    <div id="customize-dashboard" class="uk-modal-container" uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">{{ this.$t('general.customize') }}</h2>

        <div class="section-header-left">
          <h4 class="uk-text-truncate pb-2 mb-2 d-flex justify-content-between"
            style="border-bottom:1px solid #d2d2d2;">
            <span><i class="uil-eye"></i>
              {{ this.$t('survey.active') }}</span>
            <a :uk-tooltip="'title:' + $t('menu.company_dashboard.reset_menu') + '; pos: top-center'"
              href="javascript:void(0)" @click="resetMenuToDefault"><i class="uil-refresh cursor-pointer"></i></a>
          </h4>
        </div>

        <draggable v-if="widgets.data" :list="widgets.data.active" :move="checkMinMenuLimit" class="row active_menus"
          group="widget" @change="log">
          <div v-for="(element, index) in widgets.data.active" class="col-3 p-1">
            <div class="course-card uk-card-hover foradmin">
              <a href="javascript:void(0)">
                <div class="course-card-body">
                  <i :class="element.icon"></i>
                  <i class="uil-align-justify float-right" style="color:#d4d4d4;"></i>
                  <h4>{{ $t(element.key_name) }}</h4>
                </div>
              </a>
            </div>
          </div>
        </draggable>

        <div class="section-header-left mt-5">
          <h4 class="uk-text-truncate pb-2 mb-2" style="border-bottom:1px solid #d2d2d2;">
            <i class="uil-eye-slash"></i>
            {{ this.$t('survey.inactive') }}
          </h4>
        </div>

        <draggable v-if="widgets.data" :list="widgets.data.passive" :move="checkMaxMenuLimit" class="row passive_menus"
          group="widget" @change="log">
          <div v-for="(element, index) in widgets.data.passive" class="col-3 p-1">
            <div class="course-card uk-card-hover foradmin">
              <a href="javascript:void(0)">
                <div class="course-card-body">
                  <i :class="element.icon"></i>
                  <i class="uil-align-justify float-right" style="color:#d4d4d4;"></i>
                  <h4>{{ $t(element.key_name) }}</h4>
                </div>
              </a>
            </div>
          </div>
        </draggable>

        <p class="uk-text-right mt-5">
          <button class="uk-button uk-button-default uk-modal-close mr-2" type="button">{{
            $t('general.close')
          }}
          </button>
          <button class="uk-button uk-button-default uk-modal-close " type="button" @click="updateMenuSubmit">
            {{ $t('general.save') }}
          </button>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import draggable from "vuedraggable";
import store from "@/core/services/index";
import moment from "moment";
import axios from "axios";
import _ from 'lodash'

import menu_module, {
  BASE_URL as MENU_BASE_URL,
  ERROR as MENU_ERROR,
  GET_ITEM as MENU_GET_ITEM,
  ITEM as MENU_ITEM,
  MODULE_NAME as MENU_MODULE_NAME,
  RESET_BASE_URL,
  RESET_MENU,
  UPDATE_ITEM as MENU_UPDATE_ITEM,
} from "@/core/services/store/menu.module";

import content_module, {
  GET_ITEMS as CONTENT_GET_ITEMS,
  ITEMS as CONTENT_ITEMS,
  MODULE_NAME as CONTENT_MODULE_NAME,
} from "@/core/services/store/content.module";

import training_module, {
  GET_ITEMS as TRAINING_GET_ITEMS,
  ITEMS as TRAINING_ITEMS,
  MODULE_NAME as TRAINING_MODULE_NAME
} from "@/core/services/store/training.module";

export default {
  name: "Dashboard",
  components: { DataTable, draggable },
  data() {
    return {
      max_menu_items: 8,
      min_menu_items: 4,
      active_widgets: { data: {} },
      successMessage: null,
      errorMessages: [],
      menuErrorMessage: null,
      apiContentUrl: 'api/dashboard-content',
      apiTrainingUrl: 'api/dashboard-training',
      apiBestSellerUrl: 'api/best-seller',
      best_seller_loading: false,
      bestSeller: [],
      best_seller_weekly_loading: false,
      bestSellerWeekly: [],
      headers: this.apiHeaders,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MENU_MODULE_NAME, menu_module);
    registerStoreModule(CONTENT_MODULE_NAME, content_module);
    registerStoreModule(TRAINING_MODULE_NAME, training_module);
  },
  computed: {
    contentItems() {
      return store.getters[CONTENT_MODULE_NAME + "/" + CONTENT_ITEMS];
    },
    trainingItems() {
      return store.getters[TRAINING_MODULE_NAME + "/" + TRAINING_ITEMS];
    },
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() {
      },
    },
    widgets: {
      get() {
        return store.getters[MENU_MODULE_NAME + "/" + MENU_ITEM];
      },
      set(val) {

      }
    },
    errors: {
      get() {
        return store.getters[MENU_MODULE_NAME + "/" + MENU_ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    bestSellers() {
      this.best_seller_loading = true
      axios.get(`${this.apiBestSellerUrl}`, {
        headers: this.headers
      }).then((result) => {
        this.bestSeller = result.data;
        this.best_seller_loading = false
      })
    },
    weeklyBestSellers() {
      this.best_seller_weekly_loading = true
      axios.get(`${this.apiBestSellerUrl}/1`, {
        headers: this.headers
      }).then((result) => {
        this.bestSellerWeekly = result.data;
        this.best_seller_weekly_loading = false
      })
    },
    handleOnClickCard(payload) {
      this.$router.push({ name: 'company.requests.training', params: { id: payload } })
    },
    handleOnClickAssignmentCard(payload) {
      this.$router.push({ name: 'company.education.catalog.purchased', query: { purchaseId: payload } })
    },
    dateFormat(date) {
      return moment(date).format("DD MMMM YYYY HH:mm")
    },
    checkMaxMenuLimit: function (evt) {
      if (evt.from != evt.to && this.widgets.data.active.length >= this.max_menu_items) return false;
    },
    checkMinMenuLimit: function (evt) {
      if (evt.from != evt.to && this.widgets.data.active.length <= this.min_menu_items) return false;
    },
    log: function (evt) {
    },
    updateMenuSubmit() {
      this.$store.dispatch(MENU_MODULE_NAME + "/" + MENU_UPDATE_ITEM, {
        url: MENU_BASE_URL,
        contents: this.widgets
      }).then((response) => {
        if (!this.errors) {
          this.active_widgets = _.cloneDeep(this.widgets);
        }
      })


    },
    resetMenuToDefault() {
      this.$store.dispatch(MENU_MODULE_NAME + "/" + RESET_MENU, {
        url: RESET_BASE_URL,
        contents: this.widgets
      }).then(() => {
        if (!this.errors) {
        }
      })
    },
    async getMenuItems() {

      this.$store.dispatch(MENU_MODULE_NAME + "/" + MENU_GET_ITEM, {
        url: MENU_BASE_URL,
      }).then(() => {
        this.active_widgets = _.cloneDeep(this.widgets);
      });

    },
    getContentItems() {
      this.$store.dispatch(CONTENT_MODULE_NAME + "/" + CONTENT_GET_ITEMS, {
        url: this.apiContentUrl,
      });
    },
    getTrainingItems() {
      this.$store.dispatch(TRAINING_MODULE_NAME + "/" + TRAINING_GET_ITEMS, {
        url: this.apiTrainingUrl,
      });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
      this.menuErrorMessage = null;
    },
  },
  mounted() {
    if (this.authUser) {
      setTimeout(() => {
        if (!(this.isAdmin || this.isSuperAdmin)) {
          // this.$router.push({'name': '403'})
        }
      }, 1000)
    }
    this.getMenuItems()
    this.getContentItems()
    this.getTrainingItems()
    this.bestSellers()
    this.weeklyBestSellers();

  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    menuErrorMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.course-path-card img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}
</style>
